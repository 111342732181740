<template>
  <b-button variant="toggle" :class="buttonClasses" @click="toggle">
    <div class="form-check form-switch">
      <input
        class="form-check-input"
        type="checkbox"
        role="switch"
        :id="'switch_'+label.replace(/\s+/g, '')"
        :checked="status"
      >
      <label class="form-check-label" for="flexSwitchCheckDefault">
        <font-awesome-icon
          v-if="hasIcon"
          :icon="icon"
          class="fa-fw btn-toggle--icon"
        />
        <span class="btn-toggle--text">{{label}}</span>
      </label>
    </div>
  </b-button>
</template>

<script>

import { isNotEmpty } from '../../utils/validators';

export default {
  name: 'ButtonToggle',
  props: {
    icon: String,
    label: String,
    action: Function,
    hasCheckbox: Boolean,
    variant: String,
  },
  data() {
    return {
      status: false,
    };
  },
  computed: {
    buttonClasses() {
      // btn-toggle is in variant attr
      const a = isNotEmpty(this.variant) ? `btn-${this.variant}` : '';
      return this.status ? `${a} btn-toggle--active` : a;
    },
    hasIcon() {
      return isNotEmpty(this.icon);
    },
  },
  methods: {
    toggle() {
      this.status = !this.status;
      if (this.action) {
        this.action(this.status);
      }
    },
  },
};
</script>

<style scoped>

</style>
